import classNames from "classnames";
import DocumentIcon from "src/components/documenticon/DocumentIcon";
import FilmwebLink from "src/components/filmweblink/FilmwebLink";
import CmsImage from "src/components/images/CmsImage";
import CSSAdContentLabel, { CSSAdContentLabelAfter } from "src/components/styles/CSSAdContentLabel";
import theme from "src/components/themes/DefaultTheme";
import styled, { css } from 'src/lib/styles/css';
import IFilmnytt from "src/lib/types/Filmnytt";
import { ThemeValues } from "src/lib/types/IForsidenV2";
import { getImage, getUrlForDoctype } from "src/lib/types/sanity";

//#region [Props]
type SquareImageLeftTitleProps = {
    document: IFilmnytt;
    isStreamingContext?: boolean;
    themeValue?: ThemeValues;
    showSeparator?: boolean;
    className?: string;
    imageWidth?: number;
    useSectionTitle?: boolean;
};
//#endregion

//#region [Component]
export default function SquareImageLeftTitle({ document, isStreamingContext, themeValue, showSeparator, className, imageWidth = 128, useSectionTitle }: SquareImageLeftTitleProps) {
    const slug: string = document.slug?.current;
    const url = getUrlForDoctype(document._type, slug, document.isStreaming as boolean | undefined ?? isStreamingContext, document);
    const image = getImage(document);
    const title = useSectionTitle ? (document.sectionPageTitle as string ?? document.title) : document.title;

    return <SArticle className={classNames(className, showSeparator ? "withSeparator" : undefined, themeValue ?? "dark")}>
        <SFilmwebLink to={url!}
            className={classNames(document.isAdContent ? "contentAd" : undefined, themeValue ?? "dark")}>
            {image && <SImgContainer className={classNames(document.isAdContent ? "contentAd" : undefined, themeValue ?? "dark")} style={{ width: imageWidth + "px" }}>
                <SCmsImage image={image} alt={image.asset?.altText ?? ""} width={imageWidth} crop applyAspectRatio nonPosterAspect={1} />
                <SDocumentIcon document={document} />
            </SImgContainer>}
            <STitleContainer className={document.isAdContent ? "contentAd" : undefined}>
                <STitle>{document.title}</STitle>
            </STitleContainer>

        </SFilmwebLink>
    </SArticle>;
}
//#endregion

//#region [Styles]
const SArticle = styled.article`
    &.withSeparator {
        position: relative;

        &:not(:last-child):after {
            content: "";
            display: block;
            height: 3px;
            width: calc(100% - 12px);
            left: 6px;
            position: absolute;
            bottom: -17px;
            border-radius: 2px;
            background-color: var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));

            @media ${theme.mq.desktop} {
                bottom: -21.5px;

            }
        }

        &.dark:not(:last-child):after {
            background-color: var(--TEXTCOLOR_OVERRIDE, var(--textcolor));
        }
    }
`;

const SFilmwebLink = styled(FilmwebLink)`
    color: var(--TEXTCOLOR_OVERRIDE, var(--textcolor));
    &:not(.dark) {
        color: var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));
    }

	text-decoration: none;
    gap: 20px;

	display: flex;
    align-items: flex-start;

	&:hover {
		text-decoration: underline;
	}
    &.contentAd {
        color: #8fddfd;

        &:not(.dark) {
            color: var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));
        }
    }
`;

const SImgContainer = styled.div`
    border-radius: 8px;
    overflow: hidden;
    flex: 0 0 94px;
    aspect-ratio: 1 / 1;
    position: relative;

    @media ${theme.mq.desktop} {
        flex: 0 0 auto;
        &.contentAd {
            ${CSSAdContentLabel}

            &::before {

                font-size: 9px;
                padding: 5px 5px;
                right: 50%;
                transform: translateX(50%);
                top: 5px;

                @media ${theme.mq.desktop} {
                    font-size: 9px;
                    padding: 5px 8px;
                    right: 5px;
                    top: 5px;
                    transform: none;
                }
            }
            &:not(.dark) {
                &::before {
                    color: var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));
                    background-color: #8fddfd;
                }
            }

        }
    }

`;

const SCmsImage = styled(CmsImage)`
	object-fit: contain;
    display: block;
`;

const STitleContainer = styled.div`
    flex: 1 1 auto;

    &.contentAd {
        @media ${theme.mq.mobile} {
            ${CSSAdContentLabelAfter}

        }
    }
`;

const STitle = styled.h1`

    font-size: ${theme.fonts.tittelXXS.size};
    @media ${theme.mq.desktop} {
        font-size: ${theme.fonts.tittelXS.size};
    }
`;

const SDocumentIcon = styled(DocumentIcon)`
    top: auto;
    bottom: 5%;
    transform: none;
    max-width: 20%;


`
//#endregion